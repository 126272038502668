import axios from "axios";
import { format, parseISO } from "date-fns";
import { USER_ROLES } from "../constants";
import { API_PATHS, CLIENT_PATHS } from "../constants/paths";

export const axiosApi = async ({ token = null, method, endpoint, body = null }) => {
  const params = {
    method,
    data: body,
    url: endpoint.includes("?") ? `${endpoint}&agent=excel` : `${endpoint}?agent=excel`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(params);
    return response;
  } catch (error) {
    if (error.response?.status === 401 || error.response?.statusText === "Unauthorized") {
      window.location.replace(CLIENT_PATHS.BASE);
    }
    throw error;
  }
};

export const tableDateParser = (payload: Date | string) => {
  const date = parseISO(payload.toString());
  const result = format(date, "MM/dd/yyyy");

  return result;
};

export const blobToBase64 = (url: string) => {
  return new Promise(async (resolve, _) => {
    const response = await fetch(url);
    const blob = await response.blob();

    const fileReader = new FileReader();
    fileReader.readAsDataURL(blob);
    fileReader.onloadend = function () {
      resolve(fileReader.result);
    };
  });
};

export const handleGotoWebApp = () => {
  const theLink = document.createElement("a");
  theLink.href = "https://app.spikeet.com/";
  theLink.target = "_blank";
  document.body.appendChild(theLink);
  theLink.click();
  document.body.removeChild(theLink);
};

export const hasSuperAdminRole = (user: any) => {
  return user?.roles && user?.roles.includes(USER_ROLES.SUPER_ADMINISTRATOR);
};

export const checkToken = async (token: string) => {
  try {
    const res = await axiosApi({
      endpoint: API_PATHS.PROFILE,
      method: "get",
      token,
    });
    if (res.status === 200) return true;
  } catch (error) {
    return false;
  }

  return false;
};

export const registerEvent = async ({ type, token, payload, source = "excel", trigger = "user" }: any) => {
  let endpoint = API_PATHS.REGISTER_EVENT;

  const body: any = {
    type,
    payload,
    source,
    trigger,
  };

  try {
    const res = await axiosApi({
      method: "post",
      endpoint,
      body,
      token,
    });

    return res;
  } catch (error) {
    console.log("Error: ", error);
    return error;
  }
};
