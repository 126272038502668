import * as React from "react";
import CenterLayout from "../components/layouts/CenterLayout";
import Login from "../components/Login";

const LoginPage = () => {
  return (
    <CenterLayout>
      <Login />
    </CenterLayout>
  );
};

export default LoginPage;
