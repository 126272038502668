import { Dropdown, IconButton, mergeStyleSets, Toggle } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CLIENT_PATHS } from "../../constants/paths";
import useStickyState from "../../hooks/useStickyState";
import { useDataRequestsStyle } from "./DataRequests";

export const SHEET_ACTIONS = ["open_active_Sheet", "open_new_sheet"];

const SETTINGS = [
  {
    title: "Open Sheet Action",
    key: "open_sheet_action",
    value: [
      {
        text: "Open in New Sheet",
        key: "open_new_sheet",
      },
      { text: "Open in Active Sheet", key: "open_active_Sheet" },
    ],
  },
  {
    title: "Import Header",
    key: "import_header",
    value: true,
  },
  {
    title: "Sheet Auto Formatting",
    key: "auto_formatting",
    value: true,
  },
];

export const DEFAULT_SETTINGS_STATE = {
  open_sheet_action: "open_new_sheet",
  import_header: true,
  auto_formatting: true,
};

const useSettingsStyles = () =>
  mergeStyleSets({
    root: {
      padding: "25px 10px",

      "& .notes": {
        paddingTop: "8px",
        borderTop: "solid 2px #eeeeee",
        "& .label": {
          marginBottom: "6px",
        },
        "& .note": {
          fontSize: "0.9em",
          marginBottom: "8px",
          maxWidth: "350px",
        },
      },
    },

    itemContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "20px",

      "& .ms-Toggle": {
        marginBottom: 0,
      },
    },
  });

const Settings = () => {
  const { root, itemContainer } = useSettingsStyles();
  const { pageTitle } = useDataRequestsStyle();
  const navigate = useNavigate();

  const [pageState, setPageState] = useStickyState(DEFAULT_SETTINGS_STATE, "settings");

  const handleOpenSheetAction = (_: any, item: any, setting: any) => {
    setPageState({ ...pageState, [setting.key]: item.key });
  };

  const handleImportHeaderAction = (_: any, checked: any, setting: any) => {
    setPageState({ ...pageState, [setting.key]: checked });
  };

  const getControl = (setting: any) => {
    if (Array.isArray(setting.value)) {
      return (
        <Dropdown
          options={setting.value}
          defaultSelectedKey={pageState[setting.key]}
          onChange={(_: any, item: any) => handleOpenSheetAction(_, item, setting)}
        />
      );
    }
    if (typeof setting.value === "boolean") {
      return (
        <Toggle
          onText="On"
          defaultChecked={pageState[setting.key]}
          offText="Off"
          onChange={(_, checked: any) => handleImportHeaderAction(_, checked, setting)}
        />
      );
    }

    return "";
  };

  return (
    <div>
      <div className={pageTitle}>
        <div>Settings (Beta)</div>
        <div>
          <IconButton
            title="Back to Data Requests"
            ariaLabel="back-to-dash"
            className="ms-fontColor-black"
            iconProps={{ iconName: "ChromeBack" }}
            onClick={() => navigate(CLIENT_PATHS.HOME)}
          />
        </div>
      </div>
      <div className={root}>
        {SETTINGS.map((item: any) => (
          <div className={itemContainer} key={item.key}>
            <div>{item.title}:</div>
            <div>{getControl(item)}</div>
          </div>
        ))}
        <div className="notes">
          <div className="label">Notes:</div>
          <div className="note">
            1. <strong>Open in Active Sheet</strong> inserts data in the active cell
          </div>
          <div className="note">
            2. Be careful when using <strong>Open in Active Sheet</strong> as it clears the undo stack removing all undo
            history upon inserting data
          </div>
          <div className="note">
            3. Be careful when using <strong>Sheet Auto Formatting</strong> on your personal sheets as it clears the
            undo stack removing all undo history upon inserting data
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
