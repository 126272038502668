import * as React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { TextField, DefaultButton, Spinner, mergeStyleSets } from "@fluentui/react";

import { API_PATHS, CLIENT_PATHS, EXTERNAL_PATHS } from "../../constants/paths";
import { authSlice } from "../../store/createAuthSlice";
import { axiosApi, checkToken, hasSuperAdminRole, registerEvent } from "../utils";

const DEFAULT_PAGE_STATE = {
  loading: false,
  error: "",
};

const useLoginStyles = () =>
  mergeStyleSets({
    root: {
      gap: "10px",
      display: "flex",
      alignItems: "center",
      flexFlow: "column wrap",
      justifyContent: "center",

      "& .instructions": {
        color: "#287d4a",
        fontSize: "0.87em",
        textAlign: "center",

        width: "75vw",

        "& a": {
          fontWeight: 600,

          "& :visited": {
            color: "inherit",
          },
        },
      },

      "& .register": {
        fontSize: "0.9em",
        "& .link": {
          color: "#40cd78",
          fontWeight: 600,
        },
      },
    },

    title: {
      marginTop: "8px",
      marginBottom: "15px",
    },
  });

const BETA_USERS = [];

const checkBeta = (user) => {
  if (BETA_USERS.includes(user?.email) || hasSuperAdminRole(user)) {
    return true;
  } else {
    return false;
  }
};

const Login = () => {
  const classes = useLoginStyles();
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies(["token"]);
  const [setUser]: any = authSlice((state: any) => [state.setUser]);
  const [pageState, setPageState] = React.useState(DEFAULT_PAGE_STATE);

  React.useEffect(() => {
    checkAuth(cookie);
  }, []);

  const checkAuth = async (cookie: any) => {
    if (cookie.token) {
      setPageState((prev) => ({ ...prev, loading: true }));
      const auth = await checkToken(cookie.token);
      if (auth) {
        navigate(CLIENT_PATHS.HOME);
      } else {
        removeCookie("token", { secure: true, sameSite: "none" });
        setUser("null");
        setPageState((prev) => ({ ...prev, loading: false }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setPageState((_) => ({ loading: true, error: "" }));

    const { email, password } = event.target.elements;
    const body = {
      email: email.value.trim().toLowerCase(),
      password: password.value,
      remember: true,
    };

    try {
      const res = await axiosApi({
        method: "post",
        endpoint: API_PATHS.LOGIN,
        body: body,
      });

      if (res.status === 200) {
        const data = {
          id: res.data.user.id,
          name: res.data.user.name,
          email: res.data.user.email,
          tenant: res.data.user.tenant,
          isAuth: res.data.user.is_authenticated,
          stripe_customer_id: res.data.user.stripe_customer_id,
          roles: res?.data?.user?.policies.map((policy: any) => policy?.role_id?.name),
        };

        // if (!checkBeta(data)) {
        //   throw new Error("Required");
        // }

        setUser(data);
        setCookie("token", res.data.token, {
          expires: new Date(new Date().setMonth(new Date().getMonth() + 12)), // 1 Year
          secure: true,
          sameSite: "none",
        });

        registerEvent({
          type: "LOGIN",
          payload: {},
          token: res.data.token,
        });

        navigate(CLIENT_PATHS.HOME, { replace: true });
      }
    } catch (error) {
      let errorMsg = "Invalid email or password";
      if (error.response.status === 403) {
        errorMsg = "Upgrade you subscription to enable Spikeet Excel";
      }
      setPageState((prev) => ({ ...prev, error: errorMsg }));
    }

    setPageState((prev) => ({ ...prev, loading: false }));
  };

  return (
    <div className={classes.root}>
      <div>
        <img
          width="90"
          height="90"
          alt="spikeet logo"
          title="spikeet logo"
          src={require("./../../../assets/logo-filled.png")}
        />
      </div>
      <div className="ms-font-xl">Spikeet Plug-in for Excel</div>
      <div className="instructions">
        <div>
          Scan the entire market and pull historical data directly into your spreadsheets with our no-code platform
        </div>
      </div>
      <form className={classes.root} onSubmit={handleSubmit}>
        <div>
          <TextField name="email" label="Email" type="email" required />
          <TextField
            required
            name="password"
            type="password"
            label="Password"
            canRevealPassword
            revealPasswordAriaLabel="Show password"
          />
        </div>
        {pageState.error && <div className="ms-font-s ms-fontColor-red">{pageState.error}</div>}

        <div>
          {pageState.loading ? (
            <Spinner />
          ) : (
            <DefaultButton
              className="ms-welcome__action ms-textAlignCenter ms-alignHorizontalCenter"
              iconProps={{ iconName: "Lock" }}
              type="submit"
            >
              Sign in
            </DefaultButton>
          )}
        </div>
        <div className="register">
          Don't have a Spikeet account?{" "}
          <a target="_blank" className="link" href={EXTERNAL_PATHS.SIGNUP}>
            Register
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
