import * as React from "react";
import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";
import { CLIENT_PATHS } from "../../constants/paths";
import { authSlice } from "../../store/createAuthSlice";
import { checkToken } from "../utils";
import FullscreenLoader from "./FullscreenLoader";

const PrivateRoutes = () => {
  const [auth, setAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [{ token }, _, removeCookie] = useCookies(["token"]);
  const [setUser]: any = authSlice((state: any) => [state.setUser]);

  React.useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    if (token) {
      setLoading(true);
      const flag = await checkToken(token);
      if (flag) {
        setAuth(true);
      } else {
        removeCookie("token", { secure: true, sameSite: "none" });
        setUser(null);
      }
    }
    setLoading(false);
  };

  return loading ? <FullscreenLoader /> : auth && !loading ? <Outlet /> : <Navigate to={CLIENT_PATHS.BASE} />;
};

export default PrivateRoutes;
