import * as React from "react";
import DataRequests from "../components/DataRequests";
import MainLayout from "../components/layouts/MainLayout";

const HomePage = () => {
  return (
    <MainLayout>
      <DataRequests />
    </MainLayout>
  );
};

export default HomePage;
