import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";

const ContainerLoaderStyles = {
  root: {
    backgroundColor: "white",

    top: "55px",
    width: "100vw",
    height: "calc(100vh - 55px)",
    position: "absolute" as "absolute",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const ContainerLoader = () => {
  const { root } = ContainerLoaderStyles;

  return (
    <div style={root}>
      <div>
        <Spinner size={SpinnerSize.large} />
      </div>
    </div>
  );
};

export default ContainerLoader;
