const API_URL = process.env.API_URL;
const APP_URL = process.env.API_URL.includes("staging") ? "https://app.staging.spikeet.com" : "https://app.spikeet.com";

export const CLIENT_PATHS = {
  HOME: "/",
  BASE: "/taskpane.html",
  LOGIN: "/login",
  SETTINGS: "/settings",
};

export const API_PATHS = {
  LOGIN: `${API_URL}/v1/auth/jwt`,
  PROFILE: `${API_URL}/v1/profile`,
  LOGOUT: `${API_URL}/v1/profile/signout`,

  // DATA REQUEST PATHS
  GET_DATA_REQUEST: `${API_URL}/v1/profile/data-requests`,
  REQUEST_SHEET_DOWNLOAD: (id: string) => `${API_URL}/v1/data-requests/${id}/download`,
  UPDATES_SHEET_DOWNLOAD: (id: string) => `${API_URL}/v1/data-updates/${id}/download`,
  GET_DATA_UPDATES_BY_ID: (id: string) => `${API_URL}/v1/data-requests/${id}/data-updates`,

  // EVENTS
  REGISTER_EVENT: `${API_URL}/v1/user-activities`,
};

export const EXTERNAL_PATHS = {
  WEB_APP: `${APP_URL}`,
  SIGNUP: `${APP_URL}/signup`,
};
