import * as React from "react";
import { DefaultButton, IconButton, mergeStyleSets } from "@fluentui/react";

import { axiosApi, handleGotoWebApp, registerEvent } from "../../utils";
import { API_PATHS, CLIENT_PATHS } from "../../../constants/paths";
import { authSlice } from "../../../store/createAuthSlice";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const useNavbarStyles = () =>
  mergeStyleSets({
    root: {
      height: "44px",
      padding: "6px 16px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#40cd78",
      justifyContent: "space-between",

      "& .nav-brand": {
        gap: "10px",
        color: "white",
        display: "flex",
        fontWeight: 600,
        fontSize: "1.2em",
        alignItems: "center",
      },

      "& .nav-actions": {
        display: "flex",
        alignItems: "center",
      },
    },
  });

const Navbar = () => {
  const navigate = useNavigate();
  const { root } = useNavbarStyles();
  const [{ token }, setCookie, removeCookie] = useCookies(["token"]);
  const [user, setUser] = authSlice((state: any) => [state.user, state.setUser]);

  const handleLogout = async () => {
    registerEvent({
      type: "LOGOUT",
      payload: {},
      token: token,
    });

    try {
      await axiosApi({
        method: "post",
        endpoint: API_PATHS.LOGOUT,
        token,
      });
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setUser(null);
      removeCookie("token", { secure: true, sameSite: "none" });
      navigate(CLIENT_PATHS.BASE);
    }
  };

  const handleOpenSettings = () => {
    registerEvent({
      type: "GO TO SETTINGS",
      payload: {},
      token,
    });

    navigate(CLIENT_PATHS.SETTINGS);
  };

  return (
    <div className={root}>
      <div className="nav-brand">
        <div>
          <img
            width="40"
            height="40"
            alt="spikeet logo"
            title="spikeet logo"
            src={require("../../../../assets/spikeet_white.png")}
          />
        </div>
        <div style={{ marginTop: -5 }}>Spikeet</div>
      </div>
      <div className="nav-actions">
        <div>
          <IconButton
            className="ms-fontColor-white"
            iconProps={{ iconName: "World" }}
            title="Go to Spikeet Web Application"
            ariaLabel="web-link"
            onClick={handleGotoWebApp}
          />
        </div>
        <div>
          <IconButton
            className="ms-fontColor-white"
            iconProps={{ iconName: "Settings" }}
            title="Settings"
            ariaLabel="settings-button"
            onClick={handleOpenSettings}
          />
        </div>
        <div>
          <IconButton
            className="ms-fontColor-white"
            iconProps={{ iconName: "SignOut" }}
            title="Logout"
            ariaLabel="logout-button"
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
