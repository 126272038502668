import axios from "axios";
import * as React from "react";
import { useCookies } from "react-cookie";
import { Icon, IconButton, mergeStyleSets, TextField } from "@fluentui/react";

import { API_PATHS } from "../../constants/paths";
import { axiosApi, blobToBase64, registerEvent } from "../utils";

import Pagination from "./Pagination";
import RequestCard from "./RequestCard";
import ContainerLoader from "./ContainerLoader";
import FullscreenLoader from "./FullscreenLoader";
import useInterval from "../../hooks/useInterval";
import MicroLoader from "./MicroLoader";
import DailyUpdates from "./DailyUpdates";
import { DEFAULT_ORDER_BY, DEFAULT_SORT_BY } from "../../constants/defaults";
import { DEFAULT_SETTINGS_STATE, SHEET_ACTIONS } from "./Settings";
import useStickyState from "../../hooks/useStickyState";

const DEFAULT_PAGE_STATE = {
  search: "",
  message: "",
  apiCall: true,
  display: null,
  dataRequests: [],
  initialCall: true,
  requestCategory: "all",
  loading: {
    full: true,
    table: false,
    polling: false,
  },
  pagination: {
    page: 1,
    limit: 10,
    numberOfResults: 0,
    numberOfPages: 0,
  },
};

export const useDataRequestsStyle = () =>
  mergeStyleSets({
    cardContainer: {
      gap: "12px",
      display: "flex",
      margin: "12px 15px",
      flexFlow: "column wrap",

      "& .empty-list": {
        height: "60vh",

        gap: "8px",
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",

        "& .empty-icon": {
          fontSize: "2.5em",
          color: "red",
        },

        "& .link": {
          fontWeight: 600,
          color: "#40cd78",
          fontSize: "0.9em",
        },
      },
    },

    controlContainer: {
      margin: "15px 15px 5px 15px",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    search: {
      display: "flex",
      alignItems: "center",
    },

    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: 600,
      fontSize: "1.1em",
      margin: "10px 10px 0px 14px",
    },
  });

export const handleDontImportHeader = (usedRange: any) => {
  usedRange.getRow(0).delete("Up");
  usedRange.load("address");
};

export const handleAutoFormatting = (sheet: any) => {
  sheet.getRange().format.autofitColumns();
  sheet.getRange().format.autofitRows();
};

export const fetchSheetFile = async (url: string) => {
  const { data } = await axios({
    url,
    method: "GET",
    responseType: "blob",
  });

  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const file = await blobToBase64(URL.createObjectURL(blob));
  return file.toString();
};

export const writeToSheetFile = async (sheetFileb64, name, settings) => {
  await Excel.run(async (context) => {
    let startIndex = sheetFileb64.indexOf("base64,");
    let externalWorkbook = sheetFileb64.substr(startIndex + 7);

    let workbook = context.workbook;
    let activeSheet = context.workbook.worksheets.getActiveWorksheet();
    workbook.load("protection/unprotected");
    activeSheet.load("name");

    await context.sync();

    let options = {
      sheetNamesToInsert: ["Spikeet.com"],
      positionType: Excel.WorksheetPositionType.after,
      relativeTo: activeSheet.name,
    };
    workbook.insertWorksheetsFromBase64(externalWorkbook, options);

    switch (settings["open_sheet_action"]) {
      case SHEET_ACTIONS[0]:
        const newSheet = workbook.worksheets.getItem("Spikeet.com");
        const activeRange = newSheet.getUsedRangeOrNullObject();
        !settings["import_header"] && handleDontImportHeader(activeRange);

        await context.sync();

        activeSheet.activate();
        let workingSheet = context.workbook.getSelectedRange();
        workingSheet.copyFrom(activeRange, Excel.RangeCopyType.all, false, false);
        newSheet.delete();
        settings["auto_formatting"] && handleAutoFormatting(activeSheet);
        break;
      default:
        const sheet = workbook.worksheets.getItem("Spikeet.com");
        const activeRange2 = sheet.getUsedRangeOrNullObject();
        !settings["import_header"] && handleDontImportHeader(activeRange2);
        await context.sync();
        sheet.set({ name: name.length > 30 ? name.substring(0, 29) : name });
        settings["auto_formatting"] && handleAutoFormatting(sheet);
    }

    await context.sync();
  });
};

const DataRequests = () => {
  const { search, pageTitle, cardContainer, controlContainer } = useDataRequestsStyle();

  const [{ token }] = useCookies(["token"]);
  const [pageState, setPageState] = React.useState(DEFAULT_PAGE_STATE);
  const [settingsState, setSettingsState] = useStickyState(DEFAULT_SETTINGS_STATE, "settings");

  const filterUrlString = `?page=${pageState.pagination.page}&limit=${pageState.pagination.limit}&sort=${DEFAULT_SORT_BY}&order=${DEFAULT_ORDER_BY}&q=${pageState.search}`;

  React.useEffect(() => {
    if (!pageState.initialCall) {
      fetchRequests();
    } else {
      pageState.initialCall && setPageState((prev) => ({ ...prev, initialCall: false }));
    }
  }, []);

  //@ts-ignore
  React.useEffect(() => {
    if (pageState.search) {
      let timer: any = null;
      if (!timer)
        timer = setTimeout(() => {
          fetchRequests();
        }, 500);
      return () => {
        timer && clearInterval(timer);
      };
    }
    pageState.search === "" && fetchRequests();
  }, [pageState.search]);

  React.useEffect(() => {
    if (pageState.apiCall) {
      fetchRequests();
    }
  }, [pageState.apiCall]);

  useInterval(
    () => {
      fetchRequests(true);
    },
    !pageState.apiCall ? 5000 : null
  );

  const fetchRequests = async (polling = false) => {
    let loaderKey = pageState.initialCall ? "full" : polling ? "polling" : "table";
    setPageState((prev) => ({ ...prev, loading: { ...prev.loading, [loaderKey]: true } }));

    try {
      const { data } = await axiosApi({
        method: "get",
        endpoint: `${API_PATHS.GET_DATA_REQUEST}${filterUrlString}${polling ? "&trigger=system" : ""}`,
        token,
      });

      setPageState((prev) => ({ ...prev, pagination: data.metadata.pagination, dataRequests: data.result }));
    } catch (error) {}

    setPageState((prev) => ({ ...prev, loading: { full: false, table: false, polling: false } }));

    setTimeout(() => {
      pageState.apiCall && setPageState((prev) => ({ ...prev, apiCall: false }));
    }, 300);
  };

  const fetchDataRequest = async ({ name, id }: any) => {
    setPageState((prev) => ({ ...prev, loading: { ...prev.loading, full: true }, message: "Fetching Sheet File" }));

    const { data } = await axiosApi({
      method: "get",
      endpoint: API_PATHS.REQUEST_SHEET_DOWNLOAD(id),
      token,
    });

    const sheetFileb64 = await fetchSheetFile(data.signed_url);

    await writeToSheetFile(sheetFileb64, name, settingsState);

    registerEvent({
      type: "OPEN DATA REQUEST",
      payload: { name, id },
      token,
    });

    setPageState((prev) => ({ ...prev, loading: { ...prev.loading, full: false }, message: "" }));
  };

  const handleChangePage = (newPage: number) => {
    setPageState((prev: any) => ({
      ...prev,
      apiCall: true,
      pagination: { ...prev.pagination, page: newPage },
    }));
  };

  const handleClearSearch = () => {
    setPageState((prev) => ({ ...prev, search: "", pagination: { ...prev.pagination, page: 1 } }));
  };

  const handleSearch = (event) => {
    setPageState((prev) => ({ ...prev, search: event.target.value, pagination: { ...prev.pagination, page: 1 } }));
  };

  const handleDailyUpdates = (event, payload) => {
    event.stopPropagation();

    registerEvent({
      type: "OPEN DATA UPDATES",
      payload: { name: payload.name, id: payload.id },
      token,
    });

    setPageState((prev) => ({
      ...prev,
      display: {
        type: "daily-updates",
        payload,
      },
    }));
  };

  const getDisplayComponent = (type: any) => {
    switch (type) {
      case "daily-updates":
        return (
          <DailyUpdates
            token={token}
            details={pageState.display}
            back={() => setPageState((prev) => ({ ...prev, display: null }))}
          />
        );
      default:
        return (
          <>
            {pageState.loading.table ? (
              <ContainerLoader />
            ) : (
              <div>
                <div className={pageTitle}>
                  <div>Data Requests</div>
                </div>
                <div className={controlContainer}>
                  <div className={search}>
                    <div>
                      <TextField
                        placeholder="Search"
                        style={{ width: "38vw" }}
                        value={pageState.search}
                        onChange={handleSearch}
                      />
                    </div>
                    {pageState.search && (
                      <div>
                        <IconButton
                          title="Clear Search"
                          ariaLabel="clear-search"
                          className="ms-fontColor-red"
                          iconProps={{ iconName: "CalculatorMultiply" }}
                          onClick={handleClearSearch}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <Pagination
                      page={pageState.pagination.page}
                      count={pageState.pagination.numberOfPages}
                      onPageChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className={cardContainer}>
                  {pageState.dataRequests.length < 1 ? (
                    <div className="empty-list">
                      <div className="empty-icon">
                        <Icon iconName="RemoveFilter"></Icon>
                      </div>
                      <div>
                        <div style={{ marginBottom: "5px", fontSize: "1.2em" }}>You dont have any requests</div>
                        <div>
                          <a target="_blank" className="link" href="https://app.spikeet.com/">
                            Go to Spikeet web application and create data requests
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    pageState.dataRequests.map((row, idx) => (
                      <RequestCard
                        key={idx}
                        card={row}
                        onChildClick={handleDailyUpdates}
                        onClick={() => fetchDataRequest({ name: row.name, id: row.id })}
                      />
                    ))
                  )}
                </div>
              </div>
            )}
            {pageState.loading.polling && <MicroLoader />}
          </>
        );
    }
  };

  return pageState.loading.full ? (
    <FullscreenLoader message={pageState.message} />
  ) : (
    getDisplayComponent(pageState.display?.type)
  );
};

export default DataRequests;
