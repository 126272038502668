import * as React from "react";
import { IconButton, TextField, TooltipHost, TooltipOverflowMode } from "@fluentui/react";

import Pagination from "./Pagination";
import { getCardStyles } from "./RequestCard";
import ContainerLoader from "./ContainerLoader";
import FullscreenLoader from "./FullscreenLoader";
import { API_PATHS } from "../../constants/paths";
import { axiosApi, registerEvent, tableDateParser } from "../utils";
import { fetchSheetFile, useDataRequestsStyle, writeToSheetFile } from "./DataRequests";
import { DEFAULT_ORDER_BY } from "../../constants/defaults";
import { DEFAULT_SETTINGS_STATE } from "./Settings";
import useStickyState from "../../hooks/useStickyState";

const DEFAULT_SORT_BY = "date_range.start_date";
const DEFAULT_PAGE_STATE = {
  search: "",
  message: "",
  dataUpdates: [],
  apiCall: true,
  initialCall: true,
  loading: {
    full: true,
    table: false,
  },
  pagination: {
    page: 1,
    limit: 10,
    numberOfResults: 0,
    numberOfPages: 0,
  },
};

const DailyUpdates = ({ token, details, back }) => {
  const { search, pageTitle, cardContainer, controlContainer } = useDataRequestsStyle();

  const [pageState, setPageState] = React.useState(DEFAULT_PAGE_STATE);
  const [settingsState, setSettingsState] = useStickyState(DEFAULT_SETTINGS_STATE, "settings");

  const filterUrlString = `?page=${pageState.pagination.page}&limit=${pageState.pagination.limit}&sort=${DEFAULT_SORT_BY}&order=${DEFAULT_ORDER_BY}&date=${pageState.search}`;

  React.useEffect(() => {
    if (!pageState.initialCall) {
      fetchDataUpdates();
    } else {
      pageState.initialCall && setPageState((prev) => ({ ...prev, initialCall: false }));
    }
  }, []);

  //@ts-ignore
  React.useEffect(() => {
    if (pageState.search) {
      let timer: any = null;
      if (!timer)
        timer = setTimeout(() => {
          fetchDataUpdates();
        }, 500);
      return () => {
        timer && clearInterval(timer);
      };
    }
    pageState.search === "" && fetchDataUpdates();
  }, [pageState.search]);

  React.useEffect(() => {
    if (pageState.apiCall) {
      fetchDataUpdates();
    }
  }, [pageState.apiCall]);

  const fetchDataUpdates = async () => {
    let loaderKey = pageState.initialCall ? "full" : "table";
    setPageState((prev) => ({ ...prev, loading: { ...prev.loading, [loaderKey]: true } }));

    try {
      const { data } = await axiosApi({
        method: "get",
        endpoint: `${API_PATHS.GET_DATA_UPDATES_BY_ID(details.payload.id)}${filterUrlString}`,
        token,
      });

      setPageState((prev) => ({ ...prev, dataUpdates: data.result, pagination: data.metadata.pagination }));
    } catch {}

    setPageState((prev) => ({ ...prev, loading: { full: false, table: false } }));

    setTimeout(() => {
      pageState.apiCall && setPageState((prev) => ({ ...prev, apiCall: false }));
    }, 300);
  };

  const fetchDataRequest = async ({ name, id }: any) => {
    setPageState((prev) => ({ ...prev, loading: { ...prev.loading, full: true }, message: "Fetching Sheet File" }));
    name;
    const { data } = await axiosApi({
      method: "get",
      endpoint: API_PATHS.UPDATES_SHEET_DOWNLOAD(id),
      token,
    });

    const sheetFileb64 = await fetchSheetFile(data.signed_url);

    await writeToSheetFile(sheetFileb64, name, settingsState);

    registerEvent({
      type: "OPEN DATA REQUEST",
      payload: { name: details.payload.name, id: details.payload.id },
      token,
    });

    setPageState((prev) => ({ ...prev, loading: { ...prev.loading, full: false }, message: "" }));
  };

  const handleChangePage = (newPage: number) => {
    setPageState((prev: any) => ({
      ...prev,
      apiCall: true,
      pagination: { ...prev.pagination, page: newPage },
    }));
  };

  const handleClearSearch = () => {
    setPageState((prev) => ({ ...prev, search: "", pagination: { ...prev.pagination, page: 1 } }));
  };

  const handleSearch = (event) => {
    setPageState((prev) => ({ ...prev, search: event.target.value, pagination: { ...prev.pagination, page: 1 } }));
  };

  return pageState.loading.full ? (
    <FullscreenLoader message={pageState.message} />
  ) : (
    <>
      {pageState.loading.table ? (
        <ContainerLoader />
      ) : (
        <div>
          <div className={pageTitle}>
            <div>
              <TooltipHost
                overflowMode={TooltipOverflowMode.Self}
                content={details.payload.name}
                styles={{
                  root: {
                    display: "block",
                    width: "74vw",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  },
                }}
              >
                Daily Updates: {details.payload.name}
              </TooltipHost>
            </div>
            <div>
              <IconButton
                title="Back to Data Requests"
                ariaLabel="back-to-dash"
                className="ms-fontColor-black"
                iconProps={{ iconName: "ChromeBack" }}
                onClick={back}
              />
            </div>
          </div>

          <div className={controlContainer}>
            <div className={search}>
              <div>
                <TextField
                  placeholder="Search"
                  style={{ width: "38vw" }}
                  value={pageState.search}
                  onChange={handleSearch}
                />
              </div>
              {pageState.search && (
                <div>
                  <IconButton
                    title="Clear Search"
                    ariaLabel="clear-search"
                    className="ms-fontColor-red"
                    iconProps={{ iconName: "CalculatorMultiply" }}
                    onClick={handleClearSearch}
                  />
                </div>
              )}
            </div>
            <div>
              <Pagination
                page={pageState.pagination.page}
                count={pageState.pagination.numberOfPages}
                onPageChange={handleChangePage}
              />
            </div>
          </div>
          <div className={cardContainer}>
            {pageState.dataUpdates.map((row, idx) => (
              <DataUpdateCard
                key={idx}
                card={row}
                onClick={() =>
                  fetchDataRequest({ name: `${details.payload.name}-${row.date_range.start_date}`, id: row.id })
                }
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const DataUpdateCard = ({ card, onClick }) => {
  const { root } = getCardStyles();

  return (
    <div onClick={onClick} className={root}>
      <div className="card card-Finished">
        <div>
          <span className="title">{card.date_range.start_date}</span>
        </div>
        <div className="card-right">
          <div className="date">
            <i>{tableDateParser(card.created_at)}</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyUpdates;
