import * as React from "react";

const layoutStyles = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const CenterLayout = ({ children }) => {
  return <div style={layoutStyles}>{children}</div>;
};

export default CenterLayout;
