import * as React from "react";
import Navbar from "./Navbar";

const layoutStyles = {
  display: "flex",
  flexFlow: "column wrap",
  height: "100%",
};

const MainLayout = ({ children }) => {
  return (
    <div style={layoutStyles}>
      <Navbar />
      {children}
    </div>
  );
};

export default MainLayout;
