import * as React from "react";
import MainLayout from "../components/layouts/MainLayout";
import Settings from "../components/Settings";

const SettingsPage = () => {
  return (
    <MainLayout>
      <Settings />
    </MainLayout>
  );
};

export default SettingsPage;
