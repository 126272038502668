import {
  css,
  FontWeights,
  Icon,
  IconButton,
  ITooltipHostStyles,
  mergeStyleSets,
  TooltipHost,
  TooltipOverflowMode,
} from "@fluentui/react";
import * as React from "react";
import { tableDateParser } from "../utils";

export const getCardStyles = () => {
  return mergeStyleSets({
    root: {
      cursor: "pointer",

      "& .card": {
        display: "flex",
        padding: "10px 12px",
        alignItems: "center",
        justifyContent: "space-between",

        "& .title": {
          fontSize: "0.9em",
          fontWeight: 600,

          "& .completion": {
            color: "#aaba3f",
          },
        },
        "& .card-right": {
          display: "flex",
          alignItems: "center",
          gap: "5px",

          "& .date": {
            fontSize: "0.9em",
          },
        },
      },

      "& .card-Finished": {
        backgroundColor: "#e1f5e9",
        ":hover": {
          backgroundColor: "#d1e6d9",
        },
      },
      "& .card-Failed": {
        cursor: "not-allowed",
        backgroundColor: "#f5e1e1",
        ":hover": {
          backgroundColor: "e3caca",
        },
      },
      "& .card-Pending": {
        cursor: "not-allowed",
        backgroundColor: "#f5f5e1",
        ":hover": {
          backgroundColor: "e3e3cc",
        },
      },

      "& .status-Finished": {
        color: "#40cd78",
      },

      "& .status-Pending": {
        color: "#bacd40",
      },

      "& .status-Failed": {
        color: "#cd4740",
      },

      "& i": {
        marginTop: "3px",
      },
    },

    cardLabel: { display: "block", width: "40vw", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" },
  });
};

const RequestCard = ({ card, onClick, onChildClick }) => {
  const { root, cardLabel } = getCardStyles();

  return (
    <div onClick={card.status !== "Finished" ? () => {} : onClick} className={root}>
      <div className={css(`card card-${card.status}`)}>
        <div>
          <TooltipHost overflowMode={TooltipOverflowMode.Self} content={card.name} hostClassName={cardLabel}>
            <span className="title">
              {card.name} <span className="completion">{card.status === "Pending" && `(${card.completion}%)`}</span>
            </span>
          </TooltipHost>
        </div>
        <div className="card-right">
          {card.data_updates_count ? (
            <div>
              <IconButton
                className="ms-fontColor-black"
                iconProps={{ iconName: "FileRequest" }}
                title="View Daily Updates"
                ariaLabel="daily-updates"
                onClick={(event) => onChildClick(event, card)}
              />
            </div>
          ) : null}
          <div className="date">
            <i>{tableDateParser(card.created_at)}</i>
          </div>
          <div>
            <TooltipHost content={card.status}>
              <Icon className={`status-${card.status}`} iconName="LocationDot"></Icon>
            </TooltipHost>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
