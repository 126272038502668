import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";

const pageLoaderStyles = {
  root: {
    backgroundColor: "white",

    zIndex: 10,
    width: "100vw",
    height: "100vh",
    position: "absolute" as "absolute",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const FullscreenLoader = ({ message = "" }) => {
  const { root } = pageLoaderStyles;
  return (
    <div style={root}>
      <div>
        <div>
          <Spinner size={SpinnerSize.large} />
        </div>
        {message && <div style={{ marginTop: 10 }}>{message}</div>}
      </div>
    </div>
  );
};

export default FullscreenLoader;
