import { mergeStyleSets, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";

const useMicroLoaderStyles = () =>
  mergeStyleSets({
    root: {
      position: "fixed" as "fixed",
      bottom: 10,
      right: 10,
    },
  });
const MicroLoader = () => {
  const { root } = useMicroLoaderStyles();

  return (
    <div className={root}>
      <div>
        <Spinner size={SpinnerSize.xSmall} />
      </div>
    </div>
  );
};

export default MicroLoader;
