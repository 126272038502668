import { IconButton } from "@fluentui/react";
import * as React from "react";

const paginationStyles = {
  pagination: {
    gap: "4px",
    display: "flex",
    alignContent: "center",
  },
};

const Pagination = ({ page, count, onPageChange }) => {
  const { pagination } = paginationStyles;
  return (
    <div style={pagination}>
      <div>
        <IconButton
          title="previous"
          ariaLabel="previous"
          className="ms-fontColor-black"
          iconProps={{ iconName: "ChevronLeft" }}
          disabled={page <= 1}
          onClick={() => onPageChange(page - 1)}
        />
      </div>
      <div style={{ marginTop: "8px", fontSize: "0.85em" }}>{`${page} of ${count}`}</div>
      <div>
        <IconButton
          title="next"
          ariaLabel="next"
          className="ms-fontColor-black"
          iconProps={{ iconName: "ChevronRight" }}
          disabled={page === count}
          onClick={() => onPageChange(page + 1)}
        />
      </div>
    </div>
  );
};

export default Pagination;
