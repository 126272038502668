import * as React from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import Progress from "./components/Progress";
import { CLIENT_PATHS } from "../constants/paths";
import { HeroListItem } from "./components/HeroList";
import PrivateRoutes from "./components/PrivateRoute";
import SettingsPage from "./pages/Settings";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
  listItems: HeroListItem[];
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../assets/logo-filled.png")}
          message="Please sideload your addin to see app body."
        />
      );
    }

    return (
      <div className="App">
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<HomePage />} path={CLIENT_PATHS.HOME} />
            <Route element={<SettingsPage />} path={CLIENT_PATHS.SETTINGS} />
          </Route>
          <Route element={<LoginPage />} path={CLIENT_PATHS.LOGIN} />
          <Route element={<LoginPage />} path={CLIENT_PATHS.BASE} />
        </Routes>
      </div>
    );
  }
}
